<template>
  <div class="apply">
    <div class="apply-content">
      <div class="subject-title">主体信息</div>
      <div class="business-input">
        <el-select v-model="pageData.orgType" clearable placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="business-input">
        <el-input
          v-model="pageData.entityName"
          placeholder="请输入服务机构主体名称"
        ></el-input>
      </div>
      <div class="code-input">
        <el-input
          v-model="pageData.socialCreditCode"
          placeholder="请输入统一社会信用代码"
        ></el-input>
      </div>
      <div class="apply-upload">
        <el-upload
          class="avatar-uploader"
          :action="uploadUrl"
          :show-file-list="false"
          accept=".jpg,.png,.pdf"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img
            v-if="pageData.licenseImg"
            :src="pageData.licenseImg"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div class="upload-tip">
          <div>请上传营业执照</div>
          <div>最大支持10M，支持jpg/png/pdf格式</div>
        </div>
      </div>
      <div class="subject-title">申请人信息</div>
      <div class="business-input">
        <el-input
          v-model="pageData.principalName"
          placeholder="请输入申请人姓名"
        ></el-input>
      </div>
      <div>
        <el-input
          v-model="pageData.principalPhone"
          placeholder="请输入申请人电话"
        ></el-input>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '@/network/index';
export default {
  name: 'Apply',
  data () {
    return {
      pageData: {
        organizeId: 0,
        memberId: JSON.parse(window.localStorage.getItem('userinfo')).id,
        orgType: '',
        entityName: '',
        socialCreditCode: '',
        licenseImg: '',
        principalName: '',
        principalPhone: ''
      },
      options: [
        {
          value: '配套服务商',
          label: '配套服务商'
        },
        {
          value: '申报供应商',
          label: '申报供应商'
        }
      ]
    };
  },
  computed:{
    uploadUrl() {
      return `${process.env.VUE_APP_BASEURL}/pcp/user/uploadAvatar`;
    },
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.pageData.licenseImg = res.data;
    },
    beforeAvatarUpload (file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whiteList = [ 'jpg', 'png', 'pdf', 'JPG', 'PNG', 'PDF' ];

      const isLt10M = Number(file.size / 1024 / 1024);
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传文件只能是 pdf、png、pdf格式');
      }
      if (isLt10M > 10) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return whiteList && isLt10M;
    },
    checkData () {
      for (const key in this.pageData) {
        if (!this.pageData[key] && key !== 'organizeId') return false;
      }
      return true;
    },
    async submit () {
      this.pageData.memberId = JSON.parse(window.localStorage.getItem('userinfo')).id;
      if (!this.checkData()) return this.checkData();
      try {
        const { data: res } = await request({
          method: 'post',
          url: '/pcp/org/register',
          data: this.pageData
        });
        if (parseInt(res.code) === 200) {
          this.$message.success('提交信息成功');
          return true;
        }
      } catch (error) {
        this.$message.error('提交信息失败');
      }
    },
    async getList () {
      const { data: res } = await request({
        method: 'get',
        url: '/pcp/org/getOrgAuditInfo',
        params: {
          orgId: JSON.parse(window.localStorage.getItem('userinfo')).orgId
        }
      });
      if (parseInt(res.code) !== 200) { return this.$message.error('获取提交信息失败'); }
      if (parseInt(res.code) === 200 && res.data) {
        for (const key in this.pageData) {
          this.pageData[key] = res.data[key];
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.apply {
  display: flex;
  justify-content: center;
  .apply-content {
    width: 400px;
    .subject-title {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      padding-bottom: 15px;
      border-bottom: 2px solid #000000;
    }
    .business-input {
      margin: 20px 0;
    }
    .apply-upload {
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 50px;
      .upload-tip {
        flex: 1;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        opacity: 0.8;
        margin-left: 18px;
        > div:nth-child(2) {
          font-size: 14px;
          opacity: 0.3;
          margin-top: 15px;
        }
      }
    }
  }
}
/deep/.avatar-uploader .el-upload {
  border: 1px dashed #cccccc !important;
  //   opacity: 0.2;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
//   /deep/.el-upload{
//     border: 1px dashed #000000 !important;

//   }
</style>

<style>
.el-select {
  width: 100%;
}
</style>
